import './App.css';
import BooksList from './BooksList';
import BooksServerList from './BooksServerList';

function App() {
  return (
    <div>
      <h1>Bücherverwaltung</h1>
      <BooksServerList/>
    </div>
  );
}

export default App;
