import { useState } from "react";
import "./BooksList.css";
import { useEffect } from "react";
import $ from 'jquery';

function BooksServerList() {
    const [books, setBooks] = useState([]);
    const [error, setError] = useState(null);

    useEffect (() => {
        
        fetch('php/books.php')
        .then((response) => {
            if(!response.ok) {
                throw new Error('Anfrage fehlgeschlagen');
            }
            response.json();
        })
        .then((data) =>{
            setBooks(data);
        })
        .catch((error)=> setError(error))
    },[]);

    if (error !== null) {
        return <div>Es ist ein Fehler aufgetreten: {error.message}</div>
    } else if (books.length === 0) {
        return <div>Keine Bücher gefunden</div>
    } else {
        return(
            <table>
                <thead>
                    <tr>
                        <th>Titel</th>
                        <th>Autor</th>
                        <th>ISBN</th>
                        <th>Bewertung</th>
                    </tr>
                </thead>
                <tbody>
                    {books.map((book) => (
                        <tr key={book.id}>
                            <td>{book.title}</td>
                            <td>{book.auhor}</td>
                            <td>{book.isbn}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}

export default BooksServerList;